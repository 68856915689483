import React, { useEffect, useMemo, useState } from "react";
import Footer from "../../components/footer";
import { getPage } from "../../service/api/api-service";
import {
  DataType,
  HeaderType,
  TechnologiesType,
} from "../../type/technologies";
import TechList from "../../components/listTech";
import { useAppContext } from "../../appContext";

function Technologies() {
  const [data, setData] = useState<DataType>();
  const { setSettings } = useAppContext();

  useEffect(() => {
    try {
      (async () => {
        const { data } = await getPage("TECHNOLOGIES");
        setData(data);
        setSettings(data.settings);
      })();
    } catch (error) {
      // throw error
    }
  });

  const header = useMemo(() => {
    return data?.sections.filter(
      (item: HeaderType) => item.code === "TECHNOLOGY_HEADER"
    )[0];
  }, [data]);

  const techList = useMemo(() => {
    return data?.sections
      .filter((item: HeaderType) => item.code !== "TECHNOLOGY_HEADER")
      .sort((a: TechnologiesType, b: TechnologiesType) => a.id - b.id);
  }, [data]);

  const listTechComps = useMemo(() => {
    return techList?.map((item: any, index: number) => (
      <TechList key={index} tech={item} index={index} />
    ));
  }, [techList]);

  return (
    <>
      <div className="vh-100 section-technologies">
        <div className="container h-100">
          <div className="d-flex h-100 align-items-end">
            <div>
              <h1>{header?.title}</h1>
              <p dangerouslySetInnerHTML={{ __html: header?.subtitle || "" }} />
            </div>
          </div>
        </div>
      </div>
      <div className="section-technologies-list-wrapper">{listTechComps}</div>
      <Footer style={{ paddingTop: "100px" }} />
    </>
  );
}

export default Technologies;
