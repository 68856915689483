import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useWindowSize, useWindowScroll } from 'react-use';
import { ProcessType } from '../../type/process';

interface Props {
  item: ProcessType;
  index: number;
  isLast: boolean;
  active: boolean;
}

const Process: React.FunctionComponent<Props> = props => {
  const { item, index, isLast = false, active = false } = props;
  const [scrolledPercentage, setScrolledPercentage] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const intersectionRef = React.useRef<any>(null);
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const { y } = useWindowScroll();
  const clientHeight = intersectionRef?.current?.clientHeight ?? 0;

  useEffect(() => {
    if (intersectionRef.current && isMobile) {
      const bounding = intersectionRef?.current.getBoundingClientRect();
      const percentage =
        ((window.innerHeight / 2 - bounding.top) / clientHeight) * 100;
      setIsActive(percentage > 0);
      setScrolledPercentage(
        ((percentage < 0 ? 0 : percentage > 100 ? 100 : percentage) *
          clientHeight) /
          100
      );
    }
  }, [y, clientHeight, isMobile]);

  return (
    <div
      className={`section section-process fp-section fp-auto-height transition ${classNames(
        { last: isLast },
        active
      )}`}
      key={index}
    >
      {index === 0 && (
        <div className="screen-3">
          <div className="container text-center d-flex flex-column justify-content-center align-items-center">
            <span className="title-topline">Our process</span>
          </div>
        </div>
      )}
      <div
        className={`process-content ${classNames({ active: isActive })}`}
        ref={intersectionRef}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <span className="title-topline">Our process</span>
            </div>
            <div className="col-md-9">
              <div className="d-flex">
                <span className="process-marker transition flex-shrink-0">
                  {index + 1}
                  {!isLast && (
                    <>
                      <span
                        className="process-progress"
                        style={{ height: scrolledPercentage + "px" }}
                      />
                      <span
                        className="process-connector"
                        style={{ height: clientHeight }}
                      />
                    </>
                  )}
                </span>
                <div>
                  <h2 className="font-title mb-3">{item.title}</h2>
                  <div dangerouslySetInnerHTML={{__html: item.subtitle}} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;