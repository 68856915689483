import React from "react";
import classNames from "classnames";
import { ServicesType } from "../../type/services";
import { useMemo } from "react";
import { EqualHeight, EqualHeightElement } from "react-equal-height";

interface Props {
  item: ServicesType;
  index: number;
  active: boolean;
}

const Services: React.FunctionComponent<Props> = (props) => {
  const { item, index, active } = props;
  const services = useMemo(
    () => item.services.sort((a, b) => a.id - b.id),
    [item.services]
  );
  return (
    <div className={`section fp-auto-height-responsive fp-section ${classNames({ active })}`} key={index}>
      <div className="container">
        <div className="carousel-wrapper">
          <div
            id={item.code}
            className="carousel carousel-dark slide"
            data-bs-ride="carousel slide"
            data-bs-interval="5000"
          >
            <div className="carousel-header">
              <span className="d-block title-topline text-left mb-4">
                Our Services
              </span>
            </div>
            <div className="carousel-inner">
              <EqualHeight>
                {services.map((service, index) => (
                  <div
                    className={`carousel-item ${index === 0 ? "active" : ""}`}
                    key={index}
                  >
                    <EqualHeightElement name="Services">
                      <h2
                        dangerouslySetInnerHTML={{
                          __html: service.title || "",
                        }}
                      />
                      <div
                        className="slide-content"
                        dangerouslySetInnerHTML={{
                          __html: service.subtitle || "",
                        }}
                      />
                    </EqualHeightElement>
                  </div>
                ))}{" "}
              </EqualHeight>
            </div>
            <button
              className="carousel-control-prev mobile-arrow"
              type="button"
              data-bs-target={`#${item.code}`}
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next mobile-arrow"
              type="button"
              data-bs-target={`#${item.code}`}
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>

            <div className="carousel-footer d-flex flex-column flex-md-row w-100 align-items-center">
              <div className="carousel-indicators flex-fill">
                {item.services.map((service, index) => (
                  <button
                    type="button"
                    data-bs-target={`#${item.code}`}
                    data-bs-slide-to={index}
                    className={index === 0 ? "active" : ""}
                    key={index}
                  ></button>
                ))}
              </div>
              <div className="text-end pb-4 flex-fill">
                <a href="#contact">
                  <button className="btn btn-primary btn-xl rounded-pill px-5">
                    Contact us today!
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
