import React, { useState, useContext } from "react";
import { Setting } from "./type/home";

interface Anchor {
  anchor?: string;
  index?: number;
}

const AppContext = React.createContext<{
  currentPage: Anchor;
  setCurrentPage: (value: Anchor) => void;
  settings: Setting[];
  setSettings: (value: Setting[]) => void;
}>({
  currentPage: {},
  setCurrentPage: () => undefined,
  settings: [],
  setSettings: () => undefined,
});

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentPage, setCurrentPage] = useState<Anchor>({});
  const [settings, setSettings] = useState<Setting[]>([]);
  return (
    <AppContext.Provider value={{ currentPage, setCurrentPage, settings, setSettings }}>
      {children}{" "}
    </AppContext.Provider>
  );
};
