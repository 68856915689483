import React from "react";
import ReactDOM from "react-dom";
import Header from "./components/header";
import App from "./containers/home";
import Technologies from "./containers/technologies";
import reportWebVitals from "./reportWebVitals";
import "./assets/styles/style.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AppProvider } from "./appContext";
import {createRoot} from "react-dom/client";

require('dotenv').config()

const root = createRoot (

  document.getElementById("root") as HTMLElement
);

root.render(  <React.StrictMode>
  <AppProvider>
    <Router>
      <div className="app mw-100">
        <Header />
        <Routes>
          <Route path="/" element={<App />}></Route>
          <Route path="/technologies" element={<Technologies />}></Route>
          <Route path="*" element={<App />}></Route>
        </Routes>
      </div>
    </Router>
  </AppProvider>
</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
