import classNames from "classnames"
import React, { useMemo } from "react";
import { useAppContext } from "../../appContext";

const Links = [
  {
    anchor: "about-us",
    label: "About Us",
  },
  {
    anchor: "process",
    label: "Process",
  },
  {
    anchor: "our-services",
    label: "Our Services",
  },
  {
    anchor: "contact",
    label: "Contact",
  },
];

function Header() {
  const { currentPage, settings } = useAppContext();
  const logo = "logo@2x.png"

  return (
    <>
      <header
        className={`transition position-fixed py-4 py-lg-3 vw-100 ${classNames({
          scrolled: currentPage.index !== 0,
        })}`}
      >
        <div className="container px-4 px-lg-3 d-flex flex-wrap justify-content-start align-items-center">
          <a
            href="/"
            className="logo d-flex align-items-center me-md-auto text-dark text-decoration-none"
          >
            <img src={`/${logo}`} alt="Logo" />
          </a>

          <ul className="nav navbar-top d-none d-lg-flex flex-fill justify-content-end ">
            {Links.map((link, index) => (
              <li className="nav-item" key={index}>
                <a
                  href={`../#${link.anchor}`}
                  className={`nav-link ${classNames({
                    active:
                      ((currentPage.anchor === "maintenance" ||
                        currentPage.anchor === "deploy" ||
                        currentPage.anchor === "build" ||
                        currentPage.anchor === "proposal") &&
                        link.anchor === "process") ||
                      currentPage.anchor === link.anchor,
                  })}`}
                >
                  {link.label}
                </a>
              </li>
            ))}
          </ul>

          <div className="text-end flex-fill d-lg-none d-inline-block">
            <button
              className="hamburger"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#mobileMenu"
              aria-controls="mobileMenu"
            ></button>
          </div>
        </div>
      </header>
      <div
        className="offcanvas offcanvas-start mobile-menu vw-100"
        tabIndex={-1}
        id="mobileMenu"
        aria-labelledby="mobileMenuLabel"
      >
        <div className="offcanvas-header">
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul>
            {Links.map((link, index) => (
              <li className="nav-item" key={index} data-bs-dismiss="offcanvas">
                <a
                  href={`../#${link.anchor}`}
                  className={`nav-link ${classNames({
                    active: currentPage.anchor === link.anchor,
                  })}`}
                >
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Header;
