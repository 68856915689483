import React from 'react';
import { TechnologiesIcon, TechnologiesType } from '../../type/technologies';
import { useMemo } from 'react';

interface Props {
    tech: TechnologiesType;
    index: number;
  }

const TechList: React.FunctionComponent<Props> = props => {
    const { tech, index } = props;
    const technology_icons = useMemo(() => tech.technology_icons.sort((a: TechnologiesIcon, b: TechnologiesIcon) => a.id -b.id), [tech.technology_icons]);
  return (
    <div className="section-technologies-list" key={index}>
    <div className="container">
      <h3>{tech.title}</h3>
      <div className="tech-list clearfix">
        {technology_icons.map((item: TechnologiesIcon, index: number) => (
          <div className="tech-item-wrapper" key={index}>
            <img
              src={`${process.env.REACT_APP_URL}${item.image}`}
              alt={item.name}
            />
            <span>{item.name}</span>
          </div>
        ))}
      </div>
    </div>
  </div>
)};

export default TechList;