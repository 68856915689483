import React, {
  createRef,
  CSSProperties,
  useCallback,
  useMemo,
  useState,
} from "react";
import { ReactComponent as Mail } from "../../assets/icons/contact1.svg";
import { ReactComponent as Location } from "../../assets/icons/contact2.svg";
import { ReactComponent as Phone } from "../../assets/icons/contact3.svg";
import { sendContact } from "../../service/api/api-service";
import { ContactType } from "../../type/contact";
import { useAppContext } from "../../appContext";

function Footer({ style = {} }: { style?: CSSProperties }) {
  const { settings } = useAppContext();

  // Information
  const contactFormHeader ="Have an idea? Let's start a conversation!";
  const footerLocation = "Ottawa, Ontario, Canada"
  const footerPhoneNumber = "(613) 301-9071"
  const footerEmail =  "contact@mauville.ca"

  const [isSuccess, setIsSucces] = useState(false);
  const [error, setError] = useState<any[]>([]);
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>("");
  const reCaptchaRef = createRef<any>();

  const validate = (values: ContactType) => {
    const errors: any = {};
    if (!values.name) {
      errors.name = "Required";
    }
    if (!values.company_name) {
      errors.company_name = "Required";
    }
    if (!values.email) {
      errors.email = "Required";
    }
    if (!values.phone) {
      errors.phone = "Required";
    }
    if (!values.content) {
      errors.content = "Required";
    }
    return errors;
  };


  const handleSendContact = async (values: any) => {
    setIsSucces(false);
    setError([]);
    sendContact({
      ...values,
      "g-recaptcha-response": recaptchaValue,
    })
      .then((res: any) => {
        if (res) {
          setIsSucces(true);
        }
      })
      .catch((err) => {
        if (err.response.data.detail) {
          const { detail } = err.response.data;
          setError(Object.values(detail).flat());
        }
      });
    reCaptchaRef.current.reset();
  };

  const handleChangeCaptcha = useCallback((value: string | null) => {
    setRecaptchaValue(value);
  }, []);

  return (
    <div
      className={`screen-8 section section-contact position-relative`}
      style={style}
    >
      <span className="icon-footer-1"></span>
      <span className="icon-footer-2"></span>
      <span className="icon-footer-3"></span>
      <span className="icon-footer-4"></span>
      <div className="contact-wrapper d-flex flex-column h-100">
        <div className="contact flex-fill">
          <div className="d-flex align-items-end h-100">
            <div className="container">
              <div className="row py-5 footer-form">
                <div className="col-md-6 col-lg-6">
                  <h1
                    className="text-secondary"
                    dangerouslySetInnerHTML={{
                      __html: contactFormHeader || "Have an idea? Let's start a conversation!",
                    }}
                  />
                </div>
                {/*<div className="col-md-6 col-lg-5 offset-lg-1">*/}
                  {/*<form onSubmit={()=>{}}>*/}
                  {/*  <div className="mb-3">*/}
                  {/*    <input*/}
                  {/*      type="text"*/}
                  {/*      className={`form-control`}*/}
                  {/*      id="name"*/}
                  {/*      placeholder="Your Name"*/}
                  {/*      onChange={()=>{}}*/}
                  {/*      value={2}*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*  <div className="mb-3">*/}
                  {/*    <input*/}
                  {/*      type="text"*/}
                  {/*      className={`form-control`}*/}
                  {/*      id="company_name"*/}
                  {/*      placeholder="Company's Name"*/}
                  {/*      onChange={()=>{}}*/}
                  {/*      value={2}*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*  <div className="mb-3">*/}
                  {/*    <input*/}
                  {/*      type="text"*/}
                  {/*      className={`form-control`}*/}
                  {/*      id="email"*/}
                  {/*      placeholder="E-mail"*/}
                  {/*      onChange={()=>{}}*/}
                  {/*      value={2}*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*  <div className="mb-3">*/}
                  {/*    <input*/}
                  {/*      type="text"*/}
                  {/*      className={`form-control`}*/}
                  {/*      id="phone"*/}
                  {/*      placeholder="Phone"*/}
                  {/*      onChange={()=>{}}*/}
                  {/*      value={2}*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*  <div className="mb-3">*/}
                  {/*    <textarea*/}
                  {/*      className={`form-control $`}*/}
                  {/*      id="content"*/}
                  {/*      placeholder="It would be helpful if you shortly describe the project"*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*  <div className="mb-3">*/}

                  {/*  </div>*/}
                  {/*  <button*/}
                  {/*    className="w-100 btn btn-secondary btn-xl rounded-pill text-white"*/}
                  {/*    type="submit"*/}
                  {/*  >*/}
                  {/*    Let's talk!*/}
                  {/*  </button>*/}
                  {/*</form>*/}
                  {/*{error.length*/}
                  {/*  ? error.map((err, index) => (*/}
                  {/*      <div key={index} className="errMsg">*/}
                  {/*        {err}*/}
                  {/*      </div>*/}
                  {/*    ))*/}
                  {/*  : null}*/}
                  {/*{isSuccess ? (*/}
                  {/*  <div className="successMsg">Thank you!</div>*/}
                  {/*) : null}*/}
                {/*</div>*/}
              </div>
              <div className="contact-info d-lg-flex w-100 py-4">
                <div className="flex-fill contact-mail mb-3 mb-lg-0">
                  <Mail />
                  <a
                    href="mailto:contact@mauville.com"
                    dangerouslySetInnerHTML={{
                      __html: footerEmail || "",
                    }}
                  />
                </div>
                <div className="flex-fill contact-phone mb-3 mb-lg-0">
                  <Phone />
                  <a
                    href="tel:(567) 6578-675"
                    dangerouslySetInnerHTML={{
                      __html: footerPhoneNumber || "",
                    }}
                  />
                </div>
                <div className="flex-fill contact-address mb-3 mb-lg-0">
                  <div className="d-flex flex-row align-items-end">
                    <Location />
                    <p
                      className="mb-0"
                      dangerouslySetInnerHTML={{
                        __html: footerLocation || "",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer py-3 text-center">
          Copyright © 2021 Mauville Technologies
        </div>
      </div>
    </div>
  );
}

export default Footer;
