import React, {useEffect, useMemo, useState} from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import {useWindowSize} from "react-use";
import "bootstrap/dist/js/bootstrap.min.js";
import {ReactComponent as ArrowDown} from "../../assets/icons/arrow_down.svg";
import Footer from "../../components/footer";
import {useAppContext} from "../../appContext";
import classNames from "classnames";
import Process from "../../components/process";
import {getPage} from "../../service/api/api-service";
import Services from "../../components/services";
import {ProcessType} from "../../type/process";
import {ServicesType} from "../../type/services";
import {
    AboutUsType,
    HeaderType,
    TechnologiesType,
    DataType,
} from "../../type/home";

const fullpageOptions = {
    anchors: [
        "home",
        "about-us",
        "process",
        "proposal",
        "build",
        "deploy",
        "maintenance",
        "our-services",
        "contact",
    ],
    responsiveWidth: 768,
    paddingTop: "100px",
    licenseKey: `${process.env.REACT_APP_FULLPAGE_LICENSE_KEY}`,
    autoScrolling: false,
    credits: {}
};

function App() {
    const [data, setData] = useState<DataType>({
        sections: [
            {
                code: 'ABOUT_US',
                title: "We are a family run business with a passion for providing quality software design services to bring your ideas to life!",
                subtitle: 'At mauVILLE Technologies, we focus on quality. Our combined engineering and project management experience enables us to deliver stunning bespoke web, mobile, and desktop applications at highly competitive rates. '
            },
            {
                code: 'HEADER',
                title: 'mauVILLE Technologies',
                subtitle: 'Affordable and easy software design services.',
                button_text: 'Schedule Free Consultation'
            },
            {
                code: 'OUR_PROCESS',
                title: 'thing',
                subtitle: 'thing2',
                steps: [{
                    id: 1,
                    title: 'Discover',
                    subtitle: 'We work with you to gather requirements and understand your business and the needs of your customers. We then tailor our services to fit within your timeline and budget.'
                },
                    {
                        id: 2,
                        title: 'Design',
                        subtitle: 'Based on our initial conversations, we will the ncreate a development plan tailored specifically to your business needs. We will work together to refine the plan until you are completely satisfied. ' +
                            ' We can provide visual design and software design as needed.'
                    },
                    {
                        id: 3,
                        title: 'Build',
                        subtitle: 'Frequent milestones keep you informed throughout the development process. We regularly check in and gather your feedback in order to ensure that you get exactly what was envisioned.'
                    },
                    {
                        id: 4,
                        title: 'Deploy',
                        subtitle: 'Our thorough, industry-standard quality assurance process ensures that we deliver a hardened, battle-tested, secure product that will stand up to the rigors of the real world.'
                    },
                    {
                        id: 5,
                        title: 'Maintain',
                        subtitle: 'We provide free support for 90 days after deployment. We also offer ongoing support and maintenance packages to ensure that your product remains secure and up-to-date.'
                    }]
            },
            {
                code: 'OUR_SERVICES',
                title: 'Our Services',
                services: [
                    {
                        id: 1,
                        title: 'Custom Software Development',
                        subtitle: 'We develop feature-rich custom software applications that are tailored to your business needs. Our team of experienced developers will work with you to create a solution that is both scalable and secure.',
                        background_image: ''
                    },
                    {
                        id: 2,
                        title: 'Graphic Design Services',
                        subtitle: 'Our graphic design services include logo design, branding, and marketing materials. We work with you to create a cohesive brand identity that will set you apart from the competition.',
                        background_image: ''
                    },
                    {
                        id: 3,
                        title: 'Mobile App Development',
                        subtitle: 'We develop custom mobile applications for iOS and Android. Our team of experienced developers will work with you to create a solution that is both scalable and secure.',
                        background_image: ''
                    }]
            }
        ],
        settings: []
    });

    const {setCurrentPage, currentPage, setSettings} = useAppContext();
    const {width} = useWindowSize();
    const isMobile = width <= 768;

    const header = useMemo(() => {
        return data?.sections.filter(
            (item: HeaderType) => item.code === "HEADER"
        )[0];
    }, [data]);

    const aboutUs = useMemo(() => {
        return data?.sections.filter(
            (item: AboutUsType) => item.code === "ABOUT_US"
        )[0];
    }, [data]);

    const processes = useMemo(() => {
        return data?.sections
            .filter((item: ProcessType) => item.code === "OUR_PROCESS")[0]
            .steps.sort((a: ProcessType, b: ProcessType) => a.id - b.id);
    }, [data]);

    const services = useMemo(() => {
        return data?.sections.filter(
            (item: ServicesType) => item.code === "OUR_SERVICES"
        );
    }, [data]);

    const technologies = useMemo(() => {
        return data?.sections.filter(
            (item: TechnologiesType) => item.code === "TECHNOLOGIES"
        )[0];
    }, [data]);

    const processComps = useMemo(() => {
        return processes?.map((item: ProcessType, index: number) => (
            <Process
                index={index}
                key={index}
                item={item}
                active={(index + 1) === currentPage.index}
                isLast={index === processes.length - 1}
            />
        ));
    }, [processes, currentPage]);

    const serviceComps = useMemo(() => {
        return services?.map((item: ServicesType, index: number) => (
            <Services key={index} item={item} index={index}
                      active={(1 + processes?.length ?? 0 + index) === currentPage.index}/>
        ));
    }, [services, processes, currentPage]);

    return (
        <ReactFullpage
            {...fullpageOptions}
            onLeave={(origin: any, destination: any) => {
                setCurrentPage(destination);
            }}
            render={({state, fullpageApi}: any) => {
                return (
                    <ReactFullpage.Wrapper>
                        <div
                            className={`screen-1 section fp-section vh-100 overflow-hidden ${classNames({
                                "fp-auto-height": isMobile,
                                "active": currentPage.index === 0
                            })}`}
                        >
                            <div className="container position-relative">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-md-6 intro order-2 order-md-1 intro-text pb-4 pb-md-0">
                                        <h1
                                            className="font-title fw-bold mb-md-4 mb-1"
                                            dangerouslySetInnerHTML={{__html: header?.title || ""}}
                                        />
                                        <p
                                            className="mb-4"
                                            dangerouslySetInnerHTML={{
                                                __html: header?.subtitle || "",
                                            }}
                                        />
                                        <a href="#contact">
                                            <button
                                                className="btn btn-primary btn-xl rounded-pill"
                                                dangerouslySetInnerHTML={{
                                                    __html: header?.button_text || "",
                                                }}
                                            />
                                        </a>
                                    </div>
                                    <div className="col-md-6 order-1 order-md-2">
                                        <div className="main-photo">
                                            <img src="/images/computer@2x.png" alt="Computer"/>
                                        </div>
                                    </div>
                                </div>
                                <span
                                    onClick={() => fullpageApi.moveSectionDown()}
                                    className="button-scroll-down position-absolute d-none d-md-block"
                                >
                  {ArrowDown ? <ArrowDown/> : null}
                </span>
                            </div>
                        </div>

                        <div
                            className={`screen-2 section fp-section overflow-hidden ${classNames({
                                "fp-auto-height": isMobile,
                                "active": currentPage.index === 1
                            })}`}
                        >
                            <div className="container">
                                <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                                    <h2 className="font-title text-center mb-5">
                                        {aboutUs?.title}
                                    </h2>
                                    <p
                                        className="text-center"
                                        dangerouslySetInnerHTML={{
                                            __html: aboutUs?.subtitle || "",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {processComps}

                        {serviceComps}

                        <Footer/>
                    </ReactFullpage.Wrapper>
                );
            }}
        />
    );
}

export default App;
